import { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import '../App.css';

const Navbar = () => {
	const [showNavbar, setShowNavbar] = useState(false);

	const handleShowNavbar = () => {
		setShowNavbar(!showNavbar);
	};

	return (
		<nav className='navbar navbar-expand-lg py-3 sticky-top navbar-light bg-white'>
			<div className='container'>
				<div className='logo navbar-brand' href='/#home'>
					<h2>Adaa Exhibition</h2>
				</div>
				<div className='menu-icon' onClick={handleShowNavbar}>
					<span className='navbar-toggler-icon'></span>
				</div>
				<div className={`nav-elements  ${showNavbar && 'active'}`}>
					<ul>
						<li>
							<a className='nav-link' href='/#features'>
								Features
							</a>
						</li>
						<li>
							<a className='nav-link' href='/#about'>
								About
							</a>
						</li>
						<li>
							<a className='nav-link' href='/#upcoming-events'>
								Upcoming Events
							</a>
						</li>
						<li>
							<a className='nav-link' href='/#gallery'>
								Gallery
							</a>
						</li>
						<li>
							<a className='nav-link' href='/#contact'>
								Contact
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
