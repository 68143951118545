import React from 'react';
import '../App.css';

const Features = () => {
	return (
		<section id='features'>
			<div className='container'>
				<div className='row mb-5'>
					<div className='col-md-8 mx-auto text-center'>
						<h1>Features</h1>
					</div>
				</div>
				<div className='row g-4'>
					<div className='col-lg-4 col-sm-6'>
						<div className='features card-effect bounceInUp'>
							<div className='iconbox'>
								<i className='fa-regular fa-comments'></i>
							</div>
							<h5 className='mt-4 mb-2'>Customer Satisfaction</h5>
							<p>
								After completing many events in the past, we are glad to say
								that our customers are very satisfied with our.
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='features card-effect'>
							<div className='iconbox'>
								<i className='fa-solid fa-bullhorn'></i>
							</div>
							<h5 className='mt-4 mb-2'>Advertisement</h5>
							<p>
								We beleived in advertising the event traditionally as well as
								digitally using social medias and print media.
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='features card-effect'>
							<div className='iconbox'>
								<i className='fa-solid fa-users'></i>
							</div>
							<h5 className='mt-4 mb-2'>Partners and Visitors</h5>
							<p>
								The name of the event is now enough to bring the visitors the
								best partners to the event and to the venue.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Features;
