import React from 'react';

const Loader = ({ open }) => {
	if (!open) return null;

	return (
		<div className='loader-overlay'>
			<div className='loader'>
				<div className='spinner-border' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Loader;
