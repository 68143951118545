import React from 'react';
import '../App.css';
import exh1 from './../assets/exh1.jpeg';
import exh2 from './../assets/exh2.jpeg';
import exh4 from './../assets/exh4.jpeg';
import exh5 from './../assets/exh5.jpeg';
import exh6 from './../assets/exh6.jpeg';
import exh7 from './../assets/exh7.jpeg';

const SuccesfullEvents = () => {
	return (
		<section id='succesfull-events'>
			<div className='container'>
				<div className='row mb-5'>
					<div className='col-md-8 mx-auto text-center'>
						<h1>Successful Exhibiton in Surat & South Gujarat</h1>
						<p>The Glimpse of our past events.</p>
					</div>
				</div>
				<div className='row text-center g-4'>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh7} alt='savanMela' />
							<h5 className='mb-0 mt-4'>Bardoli</h5>
							<p>2-3 December,2022</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh6} alt='' />
							<h5 className='mb-0 mt-4'>Maheshwari Bhawan Surat</h5>
							<p>15-16 December,2022</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh5} alt='' />
							<h5 className='mb-0 mt-4'>Navsari</h5>
							<p>17-18 December,2022</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh4} alt='' />
							<h5 className='mb-0 mt-4'>Maheshwari Bhawan Surat</h5>
							<p>4-5 January, 2023</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh1} alt='' />
							<h5 className='mb-0 mt-4'>Bardoli</h5>
							<p>18-19 January, 2023</p>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh2} alt='' />
							<h5 className='mb-0 mt-4'>Navsari</h5>
							<p>20-21 January, 2023</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SuccesfullEvents;
