import React from 'react';
import { useNavigate } from 'react-router-dom';

const Modal = ({ open, setOpen, children, title }) => {
	const navigate = useNavigate();
	return (
		<>
			{open ? (
				<>
					<div className='modal show d-block'>
						<div className='modal-dialog'>
							<div className='modal-content'>
								{/* header */}
								<div className='modal-header'>
									<button
										className='btn-close'
										onClick={() => navigate('/', { replace: true })}
										aria-label='Close'
									>
										{/* <i className=' fa-times'></i> */}
									</button>
								</div>
								{/* body */}
								<div className='modal-body'>{children}</div>
								{/* footer */}
							</div>
						</div>
					</div>
					<div className='modal-backdrop show'></div>
				</>
			) : null}
		</>
	);
};

export default Modal;
