import React from 'react';

const Gallery = () => {
	return (
		<section id='gallery'>
			<div className='container-fluid'>
				<div className='row mb-5'>
					<div className='col-md-8 mx-auto text-center'>
						<h1>Gallery</h1>
						<p>Some glimpse of our past events</p>
					</div>
				</div>
				<div className='row g-3'>
					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvent_01.jpg')}
								alt=''
							/>
						</div>
					</div>

					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvent_03.jpg')}
								alt=''
							/>
						</div>
					</div>

					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvent_06.jpg')}
								alt=''
							/>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvent_07.jpg')}
								alt=''
							/>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvents_08.JPG')}
								alt=''
							/>
						</div>
					</div>
					<div className='col-lg-4 col-sm-6'>
						<div className='gallery'>
							<img
								src={require('./../assets/pastEvents/pastEvent_09.JPG')}
								alt=''
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Gallery;
