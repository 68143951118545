import React from 'react';
import '../App.css';
import exh8 from './../assets/exh8.jpeg';

const UpcomingEvents = () => {
	return (
		<section id='upcoming-events'>
			<div className='container'>
				<div className='row mb-5'>
					<div className='col-md-8 mx-auto text-center'>
						<h1>Upcoming Events</h1>
						<p>
							Here are the upcoming events for the exhibitions, to book your
							stall in the event please feel free to contact us.
						</p>
					</div>
				</div>
				<div className='row text-center g-4'>
					<div className='col-lg-4 col-sm-6'>
						<div className='team-member card-effect'>
							<img src={exh8} alt='savanMela' />
							<h5 className='mb-0 mt-4'>Maheshwari Bhawan</h5>
							<p>20-21 February, 2023</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default UpcomingEvents;
