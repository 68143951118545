import React, { useState } from 'react';
import '../App.css';

const Contact = () => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = () => {
		const url =
			'https://wa.me/919033584841?text=' +
			'Name: ' +
			name +
			'%0a' +
			'Phone: ' +
			phone +
			'%0a' +
			'message: ' +
			message;
		window.open(url, '_blank');
		setName('');
		setPhone('');
		setMessage('');
	};

	return (
		<section id='contact'>
			<div className='container'>
				<div className='row mb-5'>
					<div className='col-md-8 mx-auto text-center'>
						<h1>Get In Touch</h1>
						<p>
							Please fill out the form below to send us a message and we will
							get back to you as soon as possible.
						</p>
					</div>
				</div>

				<form
					action=''
					className='row g-3 justify-content-center'
					autocomplete='off'
					id='contact-form'
				>
					<div className='col-md-5'>
						<input
							id='name'
							name='name'
							type='text'
							value={name}
							onChange={(e) => setName(e.target.value)}
							className='form-control'
							placeholder='Full Name'
							required
						/>
					</div>
					<div className='col-md-5'>
						<input
							id='phone'
							type='text'
							name='phone'
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							className='form-control'
							placeholder='Enter Phone No.'
							required
						/>
					</div>

					<div className='col-md-10'>
						<textarea
							id='message'
							name='message'
							cols='30'
							rows='5'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							className='form-control'
							placeholder='Enter Message'
							required
						></textarea>
					</div>
					<div className='col-md-10 d-grid'>
						<button
							className='btn btn-primary'
							type='submit'
							onClick={handleSubmit}
						>
							Contact
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Contact;
