import React from 'react';
import '../App.css';

const Footer = () => {
	return (
		<footer>
			<div className='footer-top'>
				<div className='container'>
					<div className='row gy-4'>
						<div className='col-lg-4'>
							<h2 className='text-white'>Adaa Exhibition</h2>
						</div>
						<div className='col-lg-2'>
							<h5 className='text-white'>Brand</h5>
							<ul className='list-unstyled'>
								<li>
									<a className='list' href='#about'>
										About
									</a>
								</li>
								<li>
									<a className='list' href='#features'>
										Features
									</a>
								</li>
								<li>
									<a className='list' href='#upcoming-events'>
										Upcoming Events
									</a>
								</li>
								<li>
									<a className='list' href='#gallery'>
										Gallery
									</a>
								</li>
							</ul>
						</div>

						<div className='col-lg-4'>
							<h5 className='text-white'>Contact</h5>
							<ul className='list-unstyled'>
								<li>
									<p className='list'>
										Address: M14 Lavanya Mall near Ashok-Pan house Citylight,
										Surat, 395007
									</p>
								</li>
								<li>
									Email:
									<a className='list' href='mailto:adaaexhibition@gmail.com'>
										adaaexhibition@gmail.com
									</a>
								</li>
								<li>
									Phone:{' '}
									<a className='list' href='tell:+919033584841'>
										+91-9033584841
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='footer-bottom py-3'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6'>
							<p className='mb-0 list'>© 2022 Adaa Exhibitions</p>
						</div>
						<div className='col-md-6'>
							<div className='social-icons'>
								<a
									style={{ textDecoration: 'none' }}
									href='https://www.facebook.com/AdaaExhibition'
									target='_blank'
									rel='noreferrer'
								>
									<i className='fa-brands fa-facebook'></i>
								</a>
								<a
									style={{ textDecoration: 'none' }}
									href='https://www.instagram.com/adaaexhibition/'
									target='_blank'
									rel='noreferrer'
								>
									<i className='fa-brands fa-instagram'></i>
								</a>
								<a
									style={{ textDecoration: 'none' }}
									href='https://wa.me/919033584841/'
								>
									<i className='fa-brands fa-whatsapp'></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
