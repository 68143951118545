import React, { useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import Modal from "../components/Modal";
import Loader from "../components/Loader";

const SuccessModal = ({ message }) => {
	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<h1 className="p-4">ADAA EXHIBITION</h1>
				<h4>welcomes you</h4>

				<p>{message}</p>

				<p>
					{" "}
					Connect with us on social media to stay up-to-date with the latest
					events and updates.{" "}
				</p>
				<p>
					Like us on Facebook:{" "}
					<a
						style={{ textDecoration: "none" }}
						href="https://www.facebook.com/AdaaExhibition"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fa-brands fa-facebook"></i>
					</a>{" "}
				</p>

				<p>
					Join our community on Instagram:{" "}
					<a
						style={{ textDecoration: "none" }}
						href="https://www.instagram.com/adaaexhibition/"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fa-brands fa-instagram"></i>
					</a>
				</p>
				<p>
					For more information, please visit our
					<br /> website:{" "}
					<a
						style={{ textDecoration: "none" }}
						href="https://www.adaaexhibition.com/"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fa fa-globe" />
					</a>
				</p>
			</div>
		</>
	);
};

const phone = /^[6-9]\d{9}$/;
const Form = () => {
	const [formData, setFormData] = useState({
		name: "",
		phone: "",
		area: "",
	});
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!phone.test(formData.phone)) {
			alert("Please Enter Valid Phone Number");
			return;
		}
		const reqBody = {
			name: formData.name,
			phone: parseInt(formData.phone, 10),
			area: formData.area,
		};
		setLoading(true);
		try {
			const { data } = await axios.post(
				"https://adaaexhibition.onrender.com/api/website/addCustomer",
				reqBody
			);
			setMessage(data.msg);
			setOpenModal(true);
		} catch (error) {
			if (error.response.status === 404) {
				setMessage(error.response.data.msg);
				setOpenModal(true);
			} else {
				alert("Something went wrong please try again later");
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Loader open={loading} />
			<Navbar />
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<h1 className="p-4">ADAA EXHIBITION</h1>
				<h4>welcomes you</h4>
				<p className="p-4">
					Stay in the loop and be the first to know about exciting upcoming
					events! Fill out this form and receive exclusive information and
					updates about future events.
				</p>
				<form
					action=""
					autocomplete="off"
					id="contact-form"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						gap: 20,
					}}
				>
					<div className="col-md-10">
						<input
							id="name"
							name="name"
							type="text"
							value={formData.name}
							onChange={(e) =>
								setFormData({ ...formData, name: e.target.value.toUpperCase() })
							}
							className="form-control"
							placeholder="Full Name"
							required
							style={{
								backgroundColor: "#E3E3E3",
								borderRadius: 5,
							}}
						/>
					</div>
					<div className="col-md-9">
						<input
							id="phone"
							type="tel"
							name="phone"
							pattern="^[6-9]\d{9}$"
							value={formData.phone}
							onChange={(e) => {
								const phoneNumber = e.target.value.slice(0, 10);
								setFormData({ ...formData, phone: phoneNumber });
							}}
							className="form-control"
							placeholder="Enter Phone No."
							required
							style={{
								backgroundColor: "#E3E3E3",
								borderRadius: 5,
							}}
						/>
					</div>
					<div className="col-md-9">
						<input
							id="area"
							type="area"
							name="area"
							value={formData.area}
							onChange={(e) =>
								setFormData({ ...formData, area: e.target.value.toUpperCase() })
							}
							className="form-control"
							placeholder="Area of Residence"
							required
							style={{
								backgroundColor: "#E3E3E3",
								borderRadius: 5,
							}}
						/>
					</div>
					<div
						className="col-md-9 row "
						style={{
							margin: 15,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<button
							className="btn btn-primary"
							type="submit"
							disabled={Object.values(formData).some((value) => value === "")}
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</form>
			</div>
			{/* <Footer /> */}
			<Modal
				open={openModal}
				setOpen={setOpenModal}
				children={<SuccessModal message={message} />}
			/>
		</>
	);
};

export default Form;
