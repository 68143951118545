import React from 'react';
import '../App.css';

const Hero = () => {
	return (
		<div className='hero vh-100 d-flex align-items-center' id='home'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-7 mx-auto text-center'>
						<h1 className='display-1 text-white'>Adaa Exhibition</h1>
						<p className='text-white my-3'>
							Surat's Most Loved Fashion & Lifestyle Exhibition
						</p>
						<br />
						<a href='#features' className='btn me-2 btn-primary'>
							Learn More
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
