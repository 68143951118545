import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import About from '../components/About';
import UpcomingEvents from '../components/UpcomingEvents';
import SuccesfullEvents from '../components/SuccesfullEvents';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Main = () => {
	return (
		<>
			<Navbar />
			<Hero />
			<Features />
			<About />
			<UpcomingEvents />
			<SuccesfullEvents />
			<Gallery />
			<Contact />
			<Footer />
		</>
	);
};

export default Main;
