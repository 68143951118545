import React from 'react';
import '../App.css';

const About = () => {
	return (
		<section className='row w-100 py-0 bg-light' id='about'>
			<div className='col-lg-6 py-5'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10 offset-md-1'>
							<h1>About US</h1>
							<p>
								ADAA EXHIBITION - A TRUSTED NAME IN FASHION & LIFESTYLE
								EXHIBITION INDUSTRIES FOR LAST 10 YEARS sURAT'S Most Loved
								Fashion & Lifestyle Exhibition. ADAA EXHIBITION is Sister
								Consult Company of AD MANTRA PUBLICITY. Started in 2010 as
								Advertising Agency in SURAT. We Have Done Many Successful
								Advertising Campaign For Lifestyle & Industrial Exhibition From
								2010 to 2014. We have Started Our Own Venture in Lifestyle
								Sector as ADAA EXHIBITION in 2014.
							</p>
							<p>
								ADAA EXHIBITION Has the Experience of nearly 200+ memorable
								exhibitions across Surat & South Gujarat With highly
								experienced, professional team that is devoted to delivering
								high-quality service which makes ADAA EXHIBITION one of the most
								dependable resources for businesses in the fashion world.
							</p>
							<p>
								ADAA EXHIBITION Gives So many Options for visitors, Specially
								Wedding Shoppers & Fashion Lovers. We Belive to Give an Unique
								and differentiated shopping experience and products that are
								specifically Design for All Age Groups. We provides to visitor
								an opportunity to observe, understand and choose from
								superlative Designer Apparel Jewelry, Fashion Accessories,
								Fragrances, Cosmetics, Home Decor, Furnishing Concepts, Gifting
								Ideas, Artifacts and much more in Lifestyle Segmant.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='col-lg-6 col-img'></div>
		</section>
	);
};

export default About;
