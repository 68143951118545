import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './Screen/Main';
import Form from './Screen/Form';

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Main />} />
					<Route path='/form' element={<Form />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
